import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { applyFormValue, ControlWithErrors } from '@common/infrastructure/form-tools';
import { MParticleService } from '@common/mparticle/services/m-particle.service';
import {
    ConsumptionProfiles,
    Contracts,
    PaymentMethod,
    Product,
    Registration,
    Tariff,
} from '@enuk/model/registration';
import { SupplierData, SupplierService } from '@enuk/services/supplier.service';
import {
    FuelType,
    GetTariffPricesQuery,
    GetTariffsQuery,
    TariffLookupData,
    TariffPricesLookupData,
    TariffService,
} from '@enuk/services/tariff.service';
import { prepaymentValidator } from '@enuk/validators/prepayment.validator';
import {
    ApiRequest,
    requestErrorState,
    requestInitialState,
    requestLoadedState,
    requestLoadingState,
    requestSetState,
} from '@lang/uk/helpers/rxjs-helper';
import { NgbDate, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, merge, Observable, of, Subject } from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    filter,
    map,
    startWith,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { EnergyContractEvent } from '@common/mparticle/events/energy-contract.event';
import { getFlowPhase } from '@common/analytics/model/flow-phase';
import { removeNullishAndEmpty } from '@common/util';
import { AnalyticsService } from '@common/analytics/services/analytics.service';
import { ErrorEvent } from '@common/analytics/events/error.event';

@Component({
    selector: 'app-register-energy',
    templateUrl: './energy.component.html',
})
export class EnergyComponent implements OnChanges, OnDestroy {
    @Input()
    registration: Registration;

    @Input()
    submitting: boolean;

    @Output()
    submitted = new EventEmitter<Registration>();

    @Output()
    pendingChangesUpdate = new EventEmitter<boolean>();

    @Output()
    navigateToTab = new EventEmitter<string>();

    private destroyed$: Subject<void> = new Subject<void>();
    form: UntypedFormGroup;
    submitRequested = false;

    Contracts: typeof Contracts = Contracts;
    ConsumptionProfiles: typeof ConsumptionProfiles = ConsumptionProfiles;
    FuelType: typeof FuelType = FuelType;
    PaymentMethods: typeof PaymentMethod = PaymentMethod;
    selectedSupliers: string[];

    ConsumptionAverages = {
        Gas: {
            Low: 7500,
            Medium: 11500,
            High: 17000,
        },
        Electricity: {
            Standard: {
                Low: 1800,
                Medium: 2700,
                High: 4100,
            },
            Economy7: {
                Low: 2200,
                Medium: 3900,
                High: 6700,
            },
        },
    };

    nightTimeConsumptionPercentages: number[] = [
        5, 10, 15, 20, 25, 30, 35, 40, 42, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95,
    ];
    averageNightTimeConsumptionPercentage = 42;
    showEco7MeterRadioButtons: boolean;
    // All of the current electricity supplier's tariffs (filtered by the E7 value)
    protected electricityTariffsAll$ = new BehaviorSubject<ApiRequest<TariffLookupData[]>>(
        requestInitialState(null),
    );
    // All payment methods supported by the currently selected electricity supplier
    public electricityPaymentMethods$: Observable<ApiRequest<PaymentMethod[]>> = of(
        requestInitialState(null),
    );
    // The current electricity supplier's tariffs, filtered by the current payment method
    public electricityTariffs$: Observable<ApiRequest<TariffLookupData[]>> = of(requestInitialState(null));
    selectedElectricityTariff: TariffPricesLookupData;
    electricityTariffRequiresEndDate = false;
    // All of the current gas supplier's tariffs
    protected gasTariffsAll$ = new BehaviorSubject<ApiRequest<TariffLookupData[]>>(requestInitialState(null));
    // All payment methods supported by the currently selected gas supplier
    public gasPaymentMethods$: Observable<ApiRequest<PaymentMethod[]>> = of(requestInitialState(null));
    // The currently gas supplier's tariffs, filtered by the current payment method
    public gasTariffs$: Observable<ApiRequest<TariffLookupData[]>> = of(requestInitialState(null));
    selectedGasTariff: TariffPricesLookupData;
    gasTariffRequiresEndDate = false;
    // All of the current dual fuel supplier's tariffs (filtered by the hasEco7Meter value)
    protected dualTariffsAll$ = new BehaviorSubject<ApiRequest<TariffLookupData[]>>(
        requestInitialState(null),
    );
    // All payment methods supported by the currently selected dual fuel supplier
    public dualPaymentMethods$: Observable<ApiRequest<PaymentMethod[]>> = of(requestInitialState(null));
    // The currently dual fuel supplier's tariffs, filtered by the current payment method
    public dualTariffs$: Observable<ApiRequest<TariffLookupData[]>> = of(requestInitialState(null));
    selectedDualFuelTariff: TariffPricesLookupData;
    dualFuelTariffRequiresEndDate = false;
    errorTariffsFetch = false;
    errorTariffPrices = false;

    get contract(): ControlWithErrors {
        return this.form.get('contract');
    }
    get electricityPaymentMethod(): ControlWithErrors {
        return this.form.get('electricityPaymentMethod');
    }
    get gasPaymentMethod(): ControlWithErrors {
        return this.form.get('gasPaymentMethod');
    }
    get dualFuelPaymentMethod(): ControlWithErrors {
        return this.form.get('dualFuelPaymentMethod');
    }
    get electricitySupplierData(): ControlWithErrors {
        return this.form.get('electricitySupplierData');
    }
    get electricityTariffName(): ControlWithErrors {
        return this.form.get('electricityTariffName');
    }
    get electricityContractEndDate(): ControlWithErrors {
        return this.form.get('electricityContractEndDate');
    }
    get gasSupplierData(): ControlWithErrors {
        return this.form.get('gasSupplierData');
    }
    get gasTariffName(): ControlWithErrors {
        return this.form.get('gasTariffName');
    }
    get gasContractEndDate(): ControlWithErrors {
        return this.form.get('gasContractEndDate');
    }
    get dualSupplierData(): ControlWithErrors {
        return this.form.get('dualSupplierData');
    }
    get dualFuelTariffName(): ControlWithErrors {
        return this.form.get('dualFuelTariffName');
    }
    get dualFuelContractEndDate(): ControlWithErrors {
        return this.form.get('dualFuelContractEndDate');
    }
    get knowsEnergyConsumption(): ControlWithErrors {
        return this.form.get('knowsEnergyConsumption');
    }
    get consumptionProfile(): ControlWithErrors {
        return this.form.get('consumptionProfile');
    }
    get electricityConsumption(): ControlWithErrors {
        return this.form.get('electricityConsumption');
    }
    get gasConsumption(): ControlWithErrors {
        return this.form.get('gasConsumption');
    }
    get onlineBilling(): ControlWithErrors {
        return this.form.get('onlineBilling');
    }
    get warmHomeDiscount(): ControlWithErrors {
        return this.form.get('warmHomeDiscount');
    }
    get priorityServiceRegister(): ControlWithErrors {
        return this.form.get('priorityServiceRegister');
    }
    get hasPrepaymentMeter(): ControlWithErrors {
        return this.form.get('hasPrepaymentMeter');
    }
    get hasEco7Meter(): ControlWithErrors {
        return this.form.get('hasEco7Meter');
    }
    get nightTimeConsumptionPercentage(): ControlWithErrors {
        return this.form.get('nightTimeConsumptionPercentage');
    }
    get hasChangedSupplierInLast3Years(): ControlWithErrors {
        return this.form.get('hasChangedSupplierInLast3Years');
    }

    public activeSuppliers$: Observable<SupplierData[]>;

    private static datePickerDateToUtcDate(dateValue: NgbDate): Date {
        return dateValue ? new Date(Date.UTC(dateValue.year, dateValue.month - 1, dateValue.day)) : null;
    }

    constructor(
        private fb: UntypedFormBuilder,
        private modalService: NgbModal,
        private supplierService: SupplierService,
        public tariffService: TariffService,
        private mparticleService: MParticleService,
        private analyticsService: AnalyticsService,
    ) {
        this.form = this.fb.group(
            {
                contract: ['', Validators.required],
                electricitySupplierData: ['', this.requiredForElectricity()],
                electricityPaymentMethod: ['', this.requiredForElectricity()],
                electricityTariffName: ['', [this.requiredForElectricity(), this.noTariffServerErrors()]],
                electricityContractEndDate: [
                    '',
                    [
                        this.requiredForTariffsWithEndDate(),
                        this.endDateMustBeInTheFuture(),
                        this.butNotTooFarInTheFuture(),
                    ],
                ],
                gasSupplierData: ['', this.requiredForGas()],
                gasPaymentMethod: ['', this.requiredForGas()],
                gasTariffName: ['', [this.requiredForGas(), this.noTariffServerErrors()]],
                gasContractEndDate: [
                    '',
                    [
                        this.requiredForGasTariffsWithEndDate(),
                        this.endDateMustBeInTheFuture(),
                        this.butNotTooFarInTheFuture(),
                    ],
                ],
                dualSupplierData: ['', this.requiredForDual()],
                dualFuelPaymentMethod: ['', this.requiredForDual()],
                dualFuelTariffName: ['', [this.requiredForDual(), this.noTariffServerErrors()]],
                dualFuelContractEndDate: [
                    '',
                    [
                        this.requiredForDualFuelTariffsWithEndDate(),
                        this.endDateMustBeInTheFuture(),
                        this.butNotTooFarInTheFuture(),
                    ],
                ],
                knowsEnergyConsumption: ['', Validators.required],
                consumptionProfile: ['', this.requiredWhenConsumptionIsUnknown()],
                electricityConsumption: ['', [Validators.required, Validators.min(1), Validators.max(30000)]],
                gasConsumption: [
                    '',
                    [
                        this.requiredForGas(),
                        this.requiredForDual(),
                        this.minWhenElecOnlyOrGas(1),
                        Validators.max(70000),
                    ],
                ],
                onlineBilling: ['', Validators.required],
                warmHomeDiscount: ['', Validators.required],
                priorityServiceRegister: ['', Validators.required],
                hasPrepaymentMeter: ['', Validators.required],
                hasEco7Meter: ['', Validators.required],
                nightTimeConsumptionPercentage: ['', this.requiredForEco7Meter()],
                hasChangedSupplierInLast3Years: ['', Validators.required],
            },
            { validator: prepaymentValidator },
        );

        this.activeSuppliers$ = this.supplierService.getActiveSuppliers();

        this.form.valueChanges.subscribe(() => {
            this.pendingChangesUpdate.emit(this.form.dirty);
        });

        this.knowsEnergyConsumption.valueChanges.subscribe(() =>
            this.consumptionProfile.updateValueAndValidity(),
        );

        combineLatest([
            this.electricityPaymentMethod.valueChanges,
            this.dualFuelPaymentMethod.valueChanges,
            this.contract.valueChanges,
        ])
            .pipe(
                takeUntil(this.destroyed$),
                distinctUntilChanged(
                    ([prevElecPM, prevDualFuelPM, previousContract], [elecPM, dualFuelPM, contract]) => {
                        return (
                            prevElecPM === elecPM &&
                            prevDualFuelPM === dualFuelPM &&
                            previousContract === contract
                        );
                    },
                ),
            )
            .subscribe(([electricityPaymentMethod, dualFuelPaymentMethod, contract]) => {
                if (contract === Contracts.Dual) {
                    this.hasPrepaymentMeter.setValue(dualFuelPaymentMethod === PaymentMethod.PrePayment);
                } else {
                    this.hasPrepaymentMeter.setValue(electricityPaymentMethod === PaymentMethod.PrePayment);
                }
            });

        this.electricityConsumption.valueChanges.subscribe((x) => {
            this.setScrubbedValue(this.electricityConsumption, this.knowsEnergyConsumption.value, x);
        });

        this.gasConsumption.valueChanges.subscribe((x) => {
            this.setScrubbedValue(this.gasConsumption, this.knowsEnergyConsumption.value, x);
        });

        this.contract.valueChanges
            .pipe(
                takeUntil(this.destroyed$),
                tap(() => {
                    this.electricityTariffRequiresEndDate = false;
                    this.gasTariffRequiresEndDate = false;
                    this.dualFuelTariffRequiresEndDate = false;
                }),
            )
            .subscribe((_) => {
                this.electricityTariffName.reset();
                this.electricityContractEndDate.reset();
                this.gasTariffName.reset();
                this.gasContractEndDate.reset();
                this.dualFuelTariffName.reset();
                this.dualFuelContractEndDate.reset();

                this.gasPaymentMethod.reset();
                this.electricityPaymentMethod.reset();
                this.dualFuelPaymentMethod.reset();
                this.electricitySupplierData.reset();
                this.gasSupplierData.reset();
                this.dualSupplierData.reset();

                this.errorTariffsFetch = this.errorTariffPrices = false;
            });

        // Fetch all tariffs as soon as an electricity supplier is picked (or the contract type changes, or Eco7 was toggled)
        // (alternatively clear all electricity fields if contract was changed and supplier is now null)
        merge(
            this.electricitySupplierData.valueChanges,
            this.contract.valueChanges,
            this.hasEco7Meter.valueChanges,
        )
            .pipe(
                takeUntil(this.destroyed$),
                map(() => [this.electricitySupplierData.value, this.contract.value, this.hasEco7Meter.value]),
                distinctUntilChanged(
                    (
                        [previousSupplierData, previousContract, previousHasEco7Meter],
                        [supplierData, contract, hasEco7Meter],
                    ) =>
                        previousSupplierData?.code === supplierData?.code &&
                        previousContract === contract &&
                        previousHasEco7Meter === hasEco7Meter,
                ),
                tap(() => {
                    if (
                        this.electricitySupplierData.dirty ||
                        this.contract.dirty ||
                        this.hasEco7Meter.dirty
                    ) {
                        this.electricityPaymentMethod.reset(); // Triggers reset of tariff name, contract end date, etc below
                    }
                }),
                switchMap(([supplierData, _contract, hasEco7Meter]) => {
                    if (!supplierData) {
                        // Supplier isn't set - we're simply clearing all electricity related fields
                        return of(requestLoadedState<TariffLookupData[]>([]));
                    }

                    const query = this.getTariffs(
                        supplierData.name,
                        FuelType.Electricity,
                        null,
                        hasEco7Meter,
                    );
                    this.errorTariffsFetch = this.errorTariffPrices = false;
                    return this.tariffService.get(query).pipe(
                        map((tarrifs) => requestLoadedState<TariffLookupData[]>(tarrifs)),
                        catchError((error) => {
                            this.errorTariffsFetch = true;
                            return of(requestErrorState<TariffLookupData[]>(error, []));
                        }),
                        startWith(requestLoadingState<TariffLookupData[]>()),
                    );
                }),
            )
            .subscribe((tariffsResponse) => {
                // NOTE: subscribe since the observable chain has side-effects in tap() which are expected
                //       to run whether the dual input fields are visible or not [fix/refactor me :(]
                this.electricityTariffsAll$.next(tariffsResponse);
            });

        // Compose the list of all payment methods supported by the currently selected supplier
        this.electricityPaymentMethods$ = this.electricityTariffsAll$.pipe(
            map((tariffsResponse) =>
                requestSetState(
                    tariffsResponse.isLoading,
                    tariffsResponse.data
                        ?.map((result) => result.paymentMethods)
                        .reduce((lhs, rhs) => lhs.concat(rhs), [])
                        .filter((pm, idx, pms) => !!pm && pms.indexOf(pm) === idx),
                    tariffsResponse.error,
                ),
            ),
        );

        // The currently selected payment method
        const electricityPaymentMethod$ = this.electricityPaymentMethod.valueChanges.pipe(
            takeUntil(this.destroyed$),
            distinctUntilChanged(),
        );

        // Clear the tariff related fields whenever the electricity payment method changes
        electricityPaymentMethod$.subscribe((paymentMethod: PaymentMethod) => {
            if (this.electricityPaymentMethod.dirty || !paymentMethod) {
                this.electricityTariffName.reset();
                this.electricityContractEndDate.reset();
                this.electricityTariffRequiresEndDate = false;
            }
        });

        // Compose the list of the supplier's tariffs the user can ultimately choose from (=filtered by payment method)
        this.electricityTariffs$ = merge(this.electricityTariffsAll$, electricityPaymentMethod$).pipe(
            map(() => [this.electricityTariffsAll$.getValue(), this.electricityPaymentMethod.value]),
            map(([tariffsResponse, paymentMethod]) =>
                requestSetState(
                    tariffsResponse.isLoading,
                    paymentMethod && tariffsResponse.data
                        ? tariffsResponse.data.filter(
                              (t) => t.paymentMethods && t.paymentMethods.indexOf(paymentMethod) !== -1,
                          )
                        : [],
                    tariffsResponse.error,
                ),
            ),
        );

        combineLatest([
            this.electricityTariffName.valueChanges,
            this.hasEco7Meter.valueChanges,
            this.electricityTariffs$,
        ])
            .pipe(
                takeUntil(this.destroyed$),
                filter(([tariffName, _hasEco7Meter, _tariffsResponse]) => tariffName !== null),
                distinctUntilChanged(
                    ([previousTariffName, previousHasEco7Meter], [tariffName, hasEco7Meter]) =>
                        previousTariffName === tariffName && previousHasEco7Meter === hasEco7Meter,
                ),
                tap(([tariffName, _hasEco7Meter, tariffsResponse]) => {
                    const selectedTariff = tariffsResponse?.data.find((tariff) => tariff.name === tariffName);
                    this.electricityTariffRequiresEndDate = selectedTariff?.requiresContractEndDate === true;
                }),
                switchMap(([tariffName, hasEco7Meter]) => {
                    const query = this.getTariffPrices(
                        this.electricitySupplierData.value.name,
                        tariffName,
                        FuelType.Electricity,
                        this.electricityPaymentMethod.value,
                        hasEco7Meter,
                    );
                    this.errorTariffPrices = false;
                    return this.tariffService.getWithPrices(query).pipe(
                        catchError((_error) => {
                            this.errorTariffPrices = true;
                            return of(<TariffPricesLookupData>{});
                        }),
                    );
                }),
            )
            .subscribe((tariff) => {
                if (!tariff) {
                    return;
                }
                this.selectedElectricityTariff = tariff;
                this.selectedElectricityTariff.economy7 = this.hasEco7Meter.value;
                this.electricityTariffRequiresEndDate = tariff.requiresContractEndDate === true;
                if (tariff.name !== this.registration.product.currentTariff.electricityTariffName) {
                    this.electricityContractEndDate.reset();
                }
            });

        // Fetch all tariffs as soon as a supplier is picked (or the contract type changes)
        // (alternatively clear all gas fields if contract was changed and supplier is now null)
        merge(this.gasSupplierData.valueChanges, this.contract.valueChanges)
            .pipe(
                takeUntil(this.destroyed$),
                map(() => [this.gasSupplierData.value, this.contract.value]),
                distinctUntilChanged(
                    ([previousSupplierData, previousContract], [supplierData, contract]) =>
                        previousSupplierData?.code === supplierData?.code && previousContract === contract,
                ),
                tap(() => {
                    if (this.gasSupplierData.dirty || this.contract.dirty) {
                        this.gasPaymentMethod.reset(); // Triggers reset of tariff name, contract end date, etc below
                    }
                }),
                switchMap(([supplierData, _contract]) => {
                    if (!supplierData) {
                        // Supplier isn't set - we're simply clearing all gas related fields
                        return of(requestLoadedState<TariffLookupData[]>([]));
                    }

                    const query = this.getTariffs(supplierData.name, FuelType.Gas, null, null);
                    this.errorTariffsFetch = this.errorTariffsFetch = false;
                    return this.tariffService.get(query).pipe(
                        map((tarrifs) => requestLoadedState<TariffLookupData[]>(tarrifs)),
                        catchError((error) => {
                            this.errorTariffsFetch = true;
                            return of(requestErrorState<TariffLookupData[]>(error, []));
                        }),
                        startWith(requestLoadingState<TariffLookupData[]>()),
                    );
                }),
            )
            .subscribe((tariffsResponse) => {
                // NOTE: subscribe since the observable chain has side-effects in tap() which are expected
                //       to run whether the dual input fields are visible or not [fix/refactor me :(]
                this.gasTariffsAll$.next(tariffsResponse);
            });

        // Compose the list of all payment methods supported by the currently selected supplier
        this.gasPaymentMethods$ = this.gasTariffsAll$.pipe(
            map((tariffsResponse) =>
                requestSetState(
                    tariffsResponse.isLoading,
                    tariffsResponse.data
                        ?.map((result) => result.paymentMethods)
                        .reduce((lhs, rhs) => lhs.concat(rhs), [])
                        .filter((pm, idx, pms) => !!pm && pms.indexOf(pm) === idx),
                    tariffsResponse.error,
                ),
            ),
        );

        // The currently selected payment method
        const gasPaymentMethod$ = this.gasPaymentMethod.valueChanges.pipe(
            takeUntil(this.destroyed$),
            distinctUntilChanged(),
        );

        // Clear the tariff related fields whenever the gas payment method changes
        gasPaymentMethod$.subscribe((paymentMethod: PaymentMethod) => {
            if (this.gasPaymentMethod.dirty || !paymentMethod) {
                this.gasTariffName.reset();
                this.gasContractEndDate.reset();
                this.gasTariffRequiresEndDate = false;
            }
        });

        // Compose the list of the supplier's tariffs the user can ultimately choose from (=filtered by payment method)
        this.gasTariffs$ = merge(this.gasTariffsAll$, gasPaymentMethod$).pipe(
            map(() => [this.gasTariffsAll$.getValue(), this.gasPaymentMethod.value]),
            map(([tariffsResponse, paymentMethod]) =>
                requestSetState(
                    tariffsResponse.isLoading,
                    paymentMethod && tariffsResponse.data
                        ? tariffsResponse.data.filter(
                              (t) => t.paymentMethods && t.paymentMethods.indexOf(paymentMethod) !== -1,
                          )
                        : [],
                    tariffsResponse.error,
                ),
            ),
        );

        combineLatest([this.gasTariffName.valueChanges, this.gasTariffs$])
            .pipe(
                takeUntil(this.destroyed$),
                filter(([tariffName, _tariffsResponse]) => tariffName !== null),
                distinctUntilChanged(
                    ([previousTariffName], [tariffName]) => previousTariffName === tariffName,
                ),
                tap(([tariffName, tariffsResponse]) => {
                    const selectedTariff = tariffsResponse?.data.find((tariff) => tariff.name === tariffName);
                    this.gasTariffRequiresEndDate = selectedTariff?.requiresContractEndDate === true;
                }),
                switchMap(([tariffName]) => {
                    const query = this.getTariffPrices(
                        this.gasSupplierData.value.name,
                        tariffName,
                        FuelType.Gas,
                        this.gasPaymentMethod.value,
                        null,
                    );
                    this.errorTariffPrices = false;
                    return this.tariffService.getWithPrices(query).pipe(
                        catchError((_error) => {
                            this.errorTariffPrices = true;
                            return of(<TariffPricesLookupData>{});
                        }),
                    );
                }),
            )
            .subscribe((tariff) => {
                if (!tariff) {
                    return;
                }
                this.selectedGasTariff = tariff;
                this.selectedGasTariff.economy7 = false;
                this.gasTariffRequiresEndDate = tariff.requiresContractEndDate === true;
                if (tariff.name !== this.registration.product.currentTariff.gasTariffName) {
                    this.gasContractEndDate.reset();
                }
            });

        // Fetch all tariffs as soon as a dual fuel supplier is picked (or the contract type changes, or Eco7 was toggled))
        // (alternatively clear all dual fuel fields if contract was changed and supplier is now null)
        merge(this.dualSupplierData.valueChanges, this.contract.valueChanges, this.hasEco7Meter.valueChanges)
            .pipe(
                takeUntil(this.destroyed$),
                map(() => [this.dualSupplierData.value, this.contract.value, this.hasEco7Meter.value]),
                distinctUntilChanged(
                    (
                        [previousSupplierData, previousContract, previousHasEco7Meter],
                        [supplierData, contract, hasEco7Meter],
                    ) =>
                        previousSupplierData?.code === supplierData?.code &&
                        previousContract === contract &&
                        previousHasEco7Meter === hasEco7Meter,
                ),
                tap(() => {
                    if (this.dualSupplierData.dirty || this.contract.dirty || this.hasEco7Meter.dirty) {
                        this.dualFuelPaymentMethod.reset(); // Triggers reset of tariff name, contract end date, etc below
                    }
                }),
                switchMap(([supplierData, _contract, hasEco7Meter]) => {
                    if (!supplierData) {
                        // Supplier isn't set - we're simply clearing all dual fuel related fields
                        return of(requestLoadedState<TariffLookupData[]>([]));
                    }

                    const query = this.getTariffs(
                        supplierData.name,
                        FuelType.ElectricityAndGas,
                        null,
                        hasEco7Meter,
                    );
                    this.errorTariffsFetch = this.errorTariffsFetch = false;
                    return this.tariffService.get(query).pipe(
                        map((tarrifs) => requestLoadedState<TariffLookupData[]>(tarrifs)),
                        catchError((error) => {
                            this.errorTariffsFetch = true;
                            return of(requestErrorState<TariffLookupData[]>(error, []));
                        }),
                        startWith(requestLoadingState<TariffLookupData[]>()),
                    );
                }),
            )
            .subscribe((tariffsResponse) => {
                // NOTE: subscribe since the observable chain has side-effects in tap() which are expected
                //       to run whether the dual input fields are visible or not [fix/refactor me :(]
                this.dualTariffsAll$.next(tariffsResponse);
            });

        // Compose the list of all payment methods supported by the currently selected supplier
        this.dualPaymentMethods$ = this.dualTariffsAll$.pipe(
            map((tariffsResponse) =>
                requestSetState(
                    tariffsResponse.isLoading,
                    tariffsResponse.data
                        ?.map((result) => result.paymentMethods)
                        .reduce((lhs, rhs) => lhs.concat(rhs), [])
                        .filter((pm, idx, pms) => !!pm && pms.indexOf(pm) === idx),
                    tariffsResponse.error,
                ),
            ),
        );

        // The currently selected payment method
        const dualFuelPaymentMethod$ = this.dualFuelPaymentMethod.valueChanges.pipe(
            takeUntil(this.destroyed$),
            distinctUntilChanged(),
        );

        // Clear the tariff related fields whenever the dual fuel payment method changes
        dualFuelPaymentMethod$.subscribe((paymentMethod: PaymentMethod) => {
            if (this.dualFuelPaymentMethod.dirty || !paymentMethod) {
                this.dualFuelTariffName.reset();
                this.dualFuelContractEndDate.reset();
                this.dualFuelTariffRequiresEndDate = false;
            }
        });

        // Compose the list of the supplier's tariffs the user can ultimately choose from (=filtered by payment method)
        this.dualTariffs$ = merge(this.dualTariffsAll$, dualFuelPaymentMethod$).pipe(
            map(() => [this.dualTariffsAll$.getValue(), this.dualFuelPaymentMethod.value]),
            map(([tariffsResponse, paymentMethod]) =>
                requestSetState(
                    tariffsResponse.isLoading,
                    paymentMethod && tariffsResponse.data
                        ? tariffsResponse.data.filter(
                              (t) => t.paymentMethods && t.paymentMethods.indexOf(paymentMethod) !== -1,
                          )
                        : [],
                    tariffsResponse.error,
                ),
            ),
        );

        combineLatest([
            this.dualFuelTariffName.valueChanges,
            this.hasEco7Meter.valueChanges,
            this.electricityTariffs$,
        ])
            .pipe(
                takeUntil(this.destroyed$),
                filter(([tariffName, _hasEco7Meter, _tariffsResponse]) => tariffName !== null),
                distinctUntilChanged(
                    ([previousTariffName, previousHasEco7Meter], [tariffName, hasEco7Meter]) =>
                        previousTariffName === tariffName && previousHasEco7Meter === hasEco7Meter,
                ),
                tap(([tariffName, _hasEco7Meter, tariffsResponse]) => {
                    const selectedTariff = tariffsResponse?.data.find((tariff) => tariff.name === tariffName);
                    this.dualFuelTariffRequiresEndDate = selectedTariff?.requiresContractEndDate === true;
                }),
                switchMap(([tariffName, hasEco7Meter]) => {
                    const query = this.getTariffPrices(
                        this.dualSupplierData.value.name,
                        tariffName,
                        FuelType.ElectricityAndGas,
                        this.dualFuelPaymentMethod.value,
                        hasEco7Meter,
                    );
                    this.errorTariffPrices = false;
                    return this.tariffService.getWithPrices(query).pipe(
                        catchError((_error) => {
                            this.errorTariffPrices = true;
                            return of(<TariffPricesLookupData>{});
                        }),
                    );
                }),
            )
            .subscribe((tariff) => {
                if (!tariff) {
                    return;
                }
                this.selectedDualFuelTariff = tariff;
                this.selectedDualFuelTariff.economy7 = this.hasEco7Meter.value;
                this.dualFuelTariffRequiresEndDate = tariff.requiresContractEndDate === true;
                if (tariff.name !== this.registration.product.currentTariff.electricityTariffName) {
                    this.dualFuelContractEndDate.reset();
                }
            });

        combineLatest([
            this.gasSupplierData.valueChanges,
            this.dualSupplierData.valueChanges,
            this.electricitySupplierData.valueChanges,
        ])
            .pipe(
                map((suppliers) => suppliers as SupplierData[]),
                map((suppliers) => suppliers.filter((supplier) => supplier)),
            )
            .subscribe((x) => (this.selectedSupliers = x.map((supplier) => supplier.code)));
    }

    onSubmit(): void {
        const events = ErrorEvent.fromForm(this.form);
        events.forEach((e) => this.analyticsService.push(e));

        this.submitRequested = true;
        const profile = this.consumptionProfile.value;
        if (profile && this.knowsEnergyConsumption.value === false) {
            this.setConsumptionAverages(profile);
        }

        if (!this.hasEco7Meter.value) {
            this.nightTimeConsumptionPercentage.setValue(null);
        }

        if (this.registration.communicationBySnailMail || this.hasPrepaymentMeter.value === true) {
            this.onlineBilling.setValue(false);
        }

        this.gasConsumption.updateValueAndValidity();
        this.electricitySupplierData.updateValueAndValidity();
        this.electricityPaymentMethod.updateValueAndValidity();
        this.electricityTariffName.updateValueAndValidity();
        this.electricityContractEndDate.updateValueAndValidity();
        this.gasSupplierData.updateValueAndValidity();
        this.gasPaymentMethod.updateValueAndValidity();
        this.gasTariffName.updateValueAndValidity();
        this.gasContractEndDate.updateValueAndValidity();
        this.dualSupplierData.updateValueAndValidity();
        this.dualFuelPaymentMethod.updateValueAndValidity();
        this.dualFuelTariffName.updateValueAndValidity();
        this.dualFuelContractEndDate.updateValueAndValidity();
        this.onlineBilling.updateValueAndValidity();
        if (this.form.valid) {
            if (this.contract.value === Contracts.Electricity && this.selectedElectricityTariff) {
                const electricitySupplier: SupplierData = this.electricitySupplierData.value;
                this.registration.product.electricitySupplierCode = electricitySupplier.code;
                this.registration.product.electricitySupplier = electricitySupplier.name;
                this.registration.product.electricityPaymentMethod = this.electricityPaymentMethod.value;

                this.registration.product.currentTariff = new Tariff();
                this.registration.product.currentTariff.setElectricityTariff(
                    this.selectedElectricityTariff,
                    this.hasEco7Meter.value,
                    EnergyComponent.datePickerDateToUtcDate(this.electricityContractEndDate.value),
                    this.electricityPaymentMethod.value,
                );
            }

            if (this.contract.value === Contracts.Separate) {
                if (this.selectedElectricityTariff && this.selectedGasTariff) {
                    this.registration.product.currentTariff = new Tariff();
                }
                if (this.selectedElectricityTariff) {
                    const electricitySupplier: SupplierData = this.electricitySupplierData.value;
                    this.registration.product.electricitySupplierCode = electricitySupplier.code;
                    this.registration.product.electricitySupplier = electricitySupplier.name;
                    this.registration.product.electricityPaymentMethod = this.electricityPaymentMethod.value;

                    this.registration.product.currentTariff.setElectricityTariff(
                        this.selectedElectricityTariff,
                        this.hasEco7Meter.value,
                        EnergyComponent.datePickerDateToUtcDate(this.electricityContractEndDate.value),
                        this.electricityPaymentMethod.value,
                    );
                }
                if (this.selectedGasTariff) {
                    const gasSupplier: SupplierData = this.gasSupplierData.value;
                    this.registration.product.gasSupplierCode = gasSupplier.code;
                    this.registration.product.gasSupplier = gasSupplier.name;
                    this.registration.product.gasPaymentMethod = this.gasPaymentMethod.value;

                    this.registration.product.currentTariff.setGasTariff(
                        this.selectedGasTariff,
                        EnergyComponent.datePickerDateToUtcDate(this.gasContractEndDate.value),
                        this.gasPaymentMethod.value,
                    );
                }
            }

            if (this.contract.value === Contracts.Dual && this.selectedDualFuelTariff) {
                const dualSupplier: SupplierData = this.dualSupplierData.value;
                this.registration.product.dualSupplierCode = dualSupplier.code;
                this.registration.product.dualSupplier = dualSupplier.name;
                this.registration.product.electricityPaymentMethod = this.dualFuelPaymentMethod.value;
                this.registration.product.gasPaymentMethod = this.dualFuelPaymentMethod.value;

                this.registration.product.currentTariff = new Tariff();
                const contractEndDate = EnergyComponent.datePickerDateToUtcDate(
                    this.dualFuelContractEndDate.value,
                );
                this.registration.product.currentTariff.setElectricityTariff(
                    this.selectedDualFuelTariff,
                    this.hasEco7Meter.value,
                    contractEndDate,
                    this.electricityPaymentMethod.value,
                );
                this.registration.product.currentTariff.setGasTariff(
                    this.selectedDualFuelTariff,
                    contractEndDate,
                    this.gasPaymentMethod.value,
                );
            }

            applyFormValue(this.form, this.registration.product, [
                'electricityTariffName',
                'electricityPaymentMethod',
                'electricityContractEndDate',
                'gasTariffName',
                'gasPaymentMethod',
                'gasContractEndDate',
                'dualFuelTariffName',
                'dualFuelPaymentMethod',
                'dualFuelContractEndDate',
            ]);

            const currentTariff = this.registration.product.currentTariff;
            const energyContractEvent = new EnergyContractEvent({
                energy_contract_type: this.registration.product.contract,
                current_supplier_electricity_code: this.determineSupplierCode(
                    this.registration.product,
                    true,
                ),
                current_supplier_gas_code: this.determineSupplierCode(this.registration.product, false),
                current_supplier_electricity_name: this.determineSupplierName(
                    this.registration.product,
                    true,
                ),
                current_supplier_gas_name: this.determineSupplierName(this.registration.product, false),
                electricity_contract_end_date: currentTariff.electricityContractEndDate,
                gas_contract_end_date: currentTariff.gasContractEndDate,
                community: this.registration?.community?.name,
                project_code: this.registration?.auction?.code,
                project_status: getFlowPhase(this.registration, this.registration?.community),
                registration_number: this.registration.number,
            });
            this.mparticleService.push(removeNullishAndEmpty(energyContractEvent));

            if (this.hasPrepaymentMeter.value) {
                this.registration.product.onlineBilling = this.registration.communicationBySnailMail
                    ? true
                    : null;
            }

            this.registration.subscriptionComplete = true;
            // this is important to keep current Proposal in check
            if (this.registration.decision.wantsToCompareToOwnTariff === true) {
                this.registration.decision.wantsToCompareToOwnTariff = false;
            }
            if (this.registration.decision.wantsToCompareToRenewalTariff === true) {
                this.registration.decision.wantsToCompareToRenewalTariff = false;
            }
            this.submitted.emit(this.registration);
        }
    }

    ngOnChanges(): void {
        const formModel = Object.assign({}, this.registration.product);
        const existingElectricityDate = new Date(formModel.currentTariff.electricityContractEndDate);
        const existingGasDate = new Date(formModel.currentTariff.gasContractEndDate);
        const defaults = {
            onlineBilling: formModel.onlineBilling !== undefined ? formModel.onlineBilling : true,
            warmHomeDiscount: formModel.warmHomeDiscount ? formModel.warmHomeDiscount : false,
            priorityServiceRegister: formModel.priorityServiceRegister
                ? formModel.priorityServiceRegister
                : false,
            hasPrepaymentMeter: formModel.hasPrepaymentMeter ? formModel.hasPrepaymentMeter : false,
            nightTimeConsumptionPercentage: formModel.nightTimeConsumptionPercentage
                ? formModel.nightTimeConsumptionPercentage
                : this.averageNightTimeConsumptionPercentage,
            hasChangedSupplierInLast3Years: formModel.hasChangedSupplierInLast3Years
                ? formModel.hasChangedSupplierInLast3Years
                : false,
            dualFuelPaymentMethod:
                formModel.contract === Contracts.Dual ? formModel.electricityPaymentMethod : null,
        };

        const result = { ...formModel, ...defaults };
        this.form.reset(result);
        if (
            (formModel.contract === Contracts.Separate || formModel.contract === Contracts.Electricity) &&
            formModel.currentTariff &&
            formModel.currentTariff.electricityTariffName
        ) {
            this.electricitySupplierData.setValue({
                code: formModel.electricitySupplierCode,
                name: formModel.electricitySupplier,
            });
            this.electricityTariffName.setValue(formModel.currentTariff.electricityTariffName);
            if (formModel.currentTariff.electricityContractEndDate) {
                this.electricityContractEndDate.setValue(this.dateToNgDateStruct(existingElectricityDate));
                this.electricityContractEndDate.markAsDirty();
            }
        }

        if (
            formModel.contract === Contracts.Separate &&
            formModel.currentTariff &&
            formModel.currentTariff.gasTariffName
        ) {
            this.gasSupplierData.setValue({
                code: formModel.gasSupplierCode,
                name: formModel.gasSupplier,
            });
            this.gasTariffName.setValue(formModel.currentTariff.gasTariffName);
            if (formModel.currentTariff.gasContractEndDate) {
                this.gasContractEndDate.setValue(this.dateToNgDateStruct(existingGasDate));
                this.gasContractEndDate.markAsDirty();
            }
        }

        if (
            formModel.contract === Contracts.Dual &&
            formModel.currentTariff &&
            formModel.currentTariff.electricityTariffName
        ) {
            this.dualSupplierData.setValue({
                code: formModel.dualSupplierCode,
                name: formModel.dualSupplier,
            });
            this.dualFuelTariffName.setValue(formModel.currentTariff.electricityTariffName);
            if (formModel.currentTariff.electricityContractEndDate) {
                this.dualFuelContractEndDate.setValue(this.dateToNgDateStruct(existingElectricityDate));
                this.dualFuelContractEndDate.markAsDirty();
            }
        }
        if (this.registration.communicationBySnailMail) {
            this.onlineBilling.setValue(null);
        }
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    private dateToNgDateStruct(date: Date) {
        // Date are sent to the API as UTC (Z) but received in local time (with their original UTC value)
        return {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate(),
        };
    }

    setConsumptionAverages(profile: ConsumptionProfiles): void {
        const electricityConsumption =
            this.hasEco7Meter.value === true
                ? this.ConsumptionAverages.Electricity.Economy7[profile]
                : this.ConsumptionAverages.Electricity.Standard[profile];

        this.electricityConsumption.setValue(electricityConsumption);

        if (this.contract.value !== Contracts.Electricity) {
            this.gasConsumption.setValue(this.ConsumptionAverages.Gas[profile]);
        }
    }

    openModal(content: any) {
        this.modalService.open(content, {}).result.then(
            (_result) => {},
            (_reason) => {},
        );
    }

    requiredWhen(predicate: () => boolean): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return this.form && predicate() && !control.value ? { required: true } : null;
        };
    }

    private requiredForElectricity(): ValidatorFn {
        return this.requiredWhen(
            () => this.contract.value === Contracts.Electricity || this.contract.value === Contracts.Separate,
        );
    }

    private requiredForTariffsWithEndDate(): ValidatorFn {
        return this.requiredWhen(
            () =>
                this.electricityTariffRequiresEndDate === true &&
                (this.contract.value === Contracts.Electricity || this.contract.value === Contracts.Separate),
        );
    }

    private requiredForGasTariffsWithEndDate(): ValidatorFn {
        return this.requiredWhen(
            () => this.gasTariffRequiresEndDate === true && this.contract.value === Contracts.Separate,
        );
    }

    private requiredForDualFuelTariffsWithEndDate(): ValidatorFn {
        return this.requiredWhen(
            () => this.dualFuelTariffRequiresEndDate === true && this.contract.value === Contracts.Dual,
        );
    }

    private requiredForGas(): ValidatorFn {
        return this.requiredWhen(() => this.contract.value === Contracts.Separate);
    }

    private requiredForDual(): ValidatorFn {
        return this.requiredWhen(() => this.contract.value === Contracts.Dual);
    }

    private requiredForEco7Meter(): ValidatorFn {
        return this.requiredWhen(() => this.hasEco7Meter.value === true);
    }

    private noTariffServerErrors(): ValidatorFn {
        return (_control: AbstractControl): ValidationErrors | null => {
            if (this.form) {
                const isDirty =
                    (this.contract.value === Contracts.Dual && this.dualFuelTariffName.dirty) ||
                    (this.contract.value === Contracts.Electricity && this.electricityTariffName.dirty) ||
                    (this.contract.value === Contracts.Separate &&
                        (this.electricityTariffName.dirty || this.gasTariffName.dirty));
                if (isDirty && (this.errorTariffsFetch || this.errorTariffPrices)) {
                    return { server: true };
                }
            }
            return null;
        };
    }

    private minWhenElecOnlyOrGas(minimum: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (
                this.form &&
                control &&
                control.value < minimum &&
                this.contract &&
                (this.contract.value === Contracts.Dual || this.contract.value === Contracts.Separate)
            ) {
                return { min: true };
            }

            return null;
        };
    }

    private requiredWhenConsumptionIsUnknown(): ValidatorFn {
        return this.requiredWhen(() => this.knowsEnergyConsumption.value === false);
    }

    onNavigateToTab(tabName: string): void {
        this.navigateToTab.emit(tabName);
    }

    searchSupplier(term: string, item: any): boolean {
        return (
            (<string>item.name)
                .toLowerCase()
                .replace(/\W/g, '')
                .indexOf(term.replace(/\W/g, '').toLowerCase()) > -1
        );
    }

    compareSupplier = (lhs: SupplierData, rhs: SupplierData): boolean => lhs.code === rhs.code;

    private getTariffs(
        supplierName: string,
        fuelType: FuelType,
        paymentMethod: PaymentMethod,
        hasEco7Meter: boolean,
    ): GetTariffsQuery {
        if (supplierName === null || fuelType === null) {
            return null;
        }

        const query = new GetTariffsQuery();
        query.regionId = this.registration.contact.regionId;
        query.fuelType = fuelType;
        query.hasEco7Meter = hasEco7Meter || false;
        query.supplierName = supplierName;
        if (paymentMethod != null) {
            query.paymentMethod = paymentMethod;
        }
        return query;
    }

    private getTariffPrices(
        supplierName: string,
        tariffName: string,
        fuelType: FuelType,
        paymentMethod: PaymentMethod,
        hasEco7Meter: boolean,
    ): GetTariffPricesQuery {
        if (supplierName === null || tariffName === null) {
            return null;
        }

        const query = new GetTariffPricesQuery();
        query.regionId = this.registration.contact.regionId;
        query.fuelType = fuelType;
        query.hasEco7Meter = hasEco7Meter || false;
        query.supplierName = supplierName;
        query.paymentMethod = paymentMethod;
        query.tariffName = tariffName;
        return query;
    }

    private endDateMustBeInTheFuture(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.value) {
                return null;
            }
            const endDate = moment.utc(
                `${control.value.year}-${control.value.month}-${control.value.day}`,
                'YYYY-MM-DD',
            );
            if (moment.utc(moment.now()).isSameOrAfter(endDate)) {
                return { isInThePast: { value: control.value } };
            } else {
                return null;
            }
        };
    }

    private butNotTooFarInTheFuture(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.value) {
                return null;
            }
            const endDate = moment.utc(
                `${control.value.year}-${control.value.month}-${control.value.day}`,
                'YYYY-MM-DD',
            );
            if (moment.utc(moment.now()).add(3, 'y').isSameOrBefore(endDate)) {
                return { tooFarInTheFuture: { value: control.value } };
            } else {
                return null;
            }
        };
    }

    addYearsFromToday(years: number, lowerDate?: boolean): NgbDateStruct {
        const d = new Date();
        d.setFullYear(d.getFullYear() + years);
        return {
            year: d.getFullYear(),
            month: d.getMonth() + 1,
            day: lowerDate ? d.getDate() + 1 : d.getDate(),
        };
    }

    private setScrubbedValue(target: AbstractControl, knowsEnergy: boolean, value: number | string) {
        const shouldScrubConsumption = value && typeof value !== 'number' && knowsEnergy;
        if (!shouldScrubConsumption) {
            return;
        }

        const scrubbedValue = (value as string).replace(/([^0-9]+)/gi, '');
        if (value === scrubbedValue) {
            return;
        }

        target.setValue(scrubbedValue);
    }

    private determineSupplierCode(product: Product, elec: boolean): string {
        switch (product.contract) {
            case Contracts.Dual:
                return product.dualSupplierCode;

            case Contracts.Separate:
                return elec ? product.electricitySupplierCode : product.gasSupplierCode;
            case Contracts.Electricity:
                return elec ? product.electricitySupplierCode : null;

            default:
                return null;
        }
    }

    private determineSupplierName(product: Product, elec: boolean): string {
        switch (product.contract) {
            case Contracts.Dual:
                return product.dualSupplier;

            case Contracts.Separate:
                return elec ? product.electricitySupplier : product.gasSupplier;
            case Contracts.Electricity:
                return elec ? product.electricitySupplier : null;

            default:
                return null;
        }
    }
}
