import { InjectionToken } from '@angular/core';
import { OAuthModuleConfig, AuthConfig } from 'angular-oauth2-oidc';

export const API_URL = new InjectionToken<string>('API_URL');
export const COMMON_API_URL = new InjectionToken<string>('COMMON_API_URL');
export const CMS_URL = new InjectionToken<string>('CMS_URL');
export const CMS_ALLOW_LINKS = new InjectionToken<string>('CMS_ALLOW_LINKS');
export const CMS_PRODUCT = new InjectionToken<string>('CMS_PRODUCT');
export const CMS_REGION = new InjectionToken<string>('CMS_REGION');
export const CMS_LOCALE = new InjectionToken<string>('CMS_LOCALE');
export const CMS_AVAILABLE_LOCALES = new InjectionToken<string>(
    'CMS_AVAILABLE_LOCALES',
);
export const BUSINESS = new InjectionToken<string>('BUSINESS');
export const CANONICAL_COMMUNITY = new InjectionToken<string>(
    'CANONICAL_COMMUNITY',
);
export const ANALYTICS_API_URL = new InjectionToken<string>(
    'ANALYTICS_API_URL',
);
export const ANALYTICS_SEND_TO_TAGMANAGER = new InjectionToken<boolean>(
    'ANALYTICS_SEND_TO_TAGMANAGER',
);
export const ANALYTICS_LOG_TO_CONSOLE = new InjectionToken<boolean>(
    'ANALYTICS_LOG_TO_CONSOLE',
);
export const ANALYTICS_CROSS_BUSINESS_URLS = new InjectionToken<string[]>(
    'ANALYTICS_CROSS_BUSINESS_URLS',
);
export const COOKIE_POLICY_URL = new InjectionToken<string>(
    'COOKIE_POLICY_URL',
);
export const PAYMENTS_API_URL = new InjectionToken<string>('PAYMENTS_API_URL');
export const DEFAULT_COMMUNITY = new InjectionToken<string>(
    'DEFAULT_COMMUNITY',
);
export const CAN_ACTIVATE_LANDING = new InjectionToken<boolean>(
    'CAN_ACTIVATE_LANDING',
);
export const BRAND_AS_URL_QUERY_PARAMETER = new InjectionToken<boolean>(
    'BRAND_AS_URL_QUERY_PARAMETER',
);
export const CAN_ACTIVATE_PREFERENCE_CENTER = new InjectionToken<boolean>(
    'CAN_ACTIVATE_PREFERENCE_CENTER',
);
export const COMMUNITY_FORUM_URL = new InjectionToken<string>(
    'COMMUNITY_FORUM_URL',
);
export const MPARTICLE_KEY_PROVIDER_URL = new InjectionToken<string>(
    'MPARTICLE_KEY_PROVIDER_URL',
);
export const MPARTICLE_DEVELOPMENT_MODE = new InjectionToken<string>(
    'MPARTICLE_DEVELOPMENT_MODE',
);

export const APPLICATION_INSIGHTS_CONNECTION_STRING = new InjectionToken<string>('APPLICATION_INSIGHTS_CONNECTION_STRING');

export const configureOAuth = (...apiUris: string[]): OAuthModuleConfig => {
    return {
        resourceServer: {
            allowedUrls: apiUris,
            sendAccessToken: true,
        },
    };
};

export const getAuthConfig = (identityUri: string): AuthConfig => {
    const bases = document.getElementsByTagName('base');
    let baseHref = '/';
    if (bases.length > 0) {
        baseHref = bases[0].href;
    }

    return {
        issuer: identityUri,
        logoutUrl: identityUri + '/connect/endsession',
        redirectUri: baseHref,
        silentRefreshRedirectUri: baseHref + 'silent-refresh.html',
        clientId: 'ichoosr_backoffice_app',
        scope: 'openid profile ichoosr_api',
        sessionChecksEnabled: true,
    };
};

export const getAuthConfigFromEnv = (
    env: object,
    currentHost: string,
): AuthConfig => {
    const environment: EnvironmentObj = Object.assign(
        { identityUri: '', customDomainIdentityUris: [] },
        env,
    );
    let url: string;

    // We need the naked domain for when we host on a sub-domain, e.g. energie-vergelijker-demo.eigenhuis.nl
    const nakedDomain = getNakedDomain(currentHost);

    // See if we have custom identity servers for third party domains and there is a match with the current url visited
    if (environment.customDomainIdentityUris.length > 0) {
        url = environment.customDomainIdentityUris.find((uri) =>
            uri.endsWith(nakedDomain),
        );
    }

    // If not configured, fallback to default identity server
    if (!url) {
        url = environment.identityUri;
    }

    return getAuthConfig(url);
};

export const fetchCookiePolicyUrlFromEnv = (
    mapping: object,
    fallback: string,
    currentHost: string,
): string => {
    const nakedDomain = getNakedDomain(currentHost);

    if (mapping.hasOwnProperty(nakedDomain)) {
        return mapping[nakedDomain];
    }

    return fallback;
};

const tldsWithTwoParts = ['.co.uk'];
const getNakedDomain = (host: string) => {
    // TODO: Improve on thsi implementation
    let domainPartsInNakedDomain = 2;
    tldsWithTwoParts.forEach((tld) => {
        if (host.endsWith(tld)) {
            domainPartsInNakedDomain = 3;
            return;
        }
    });

    const nakedDomain = host
        .split('.')
        .slice(-domainPartsInNakedDomain)
        .join('.');

    return nakedDomain;
};

interface EnvironmentObj {
    identityUri: string;
    customDomainIdentityUris: string[];
}
