import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeGB from '@angular/common/locales/en-GB';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { CountdownComponent } from '@common/components/countdown/countdown.component';
import { PrivacyPolicyComponent } from '@common/components/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from '@common/components/terms-and-conditions/terms-and-conditions.component';
import { ChatService } from '@common/services/chat.service';
import { CommunityService } from '@common/services/community.service';
import { InMemoryAuthStorage } from '@common/services/in-memory-auth-storage';
import { WINDOW_PROVIDERS } from '@common/services/window.service';
import { CmsItemsCheckListComponent } from '@lang/uk/components/cms-items-checklist/cms-items-checklist.component';
import { HelpdeskComponent } from '@lang/uk/components/helpdesk/helpdesk.component';
import { ListMenuComponent } from '@lang/uk/components/list-menu/list-menu.component';
import { RegisterNowBannerComponent } from '@lang/uk/components/register-now/register-now-banner.component';
import { RegisterNowButtonComponent } from '@lang/uk/components/register-now/register-now-button.component';
import { SurveyBannerComponent } from '@lang/uk/components/survey-banner/survey-banner.component';
import { MarkOptionalDirective } from '@lang/uk/directives/optional-directive';
import { BrandService } from '@lang/uk/services/brand.service';
import { InfoItemServiceBase } from '@lang/uk/services/info-item.service';
import { MenuItemService } from '@lang/uk/services/menu-item.service';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import {
    AuthConfig,
    OAuthModule,
    OAuthModuleConfig,
    OAuthStorage,
    ValidationHandler,
} from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { FileUploadModule } from 'ng2-file-upload';
import { NgxMaskModule } from 'ngx-mask';
import { AppCommonModule } from '../../_common/app-common.module';
import { HelpdeskVariant1Component } from '@lang/uk/components/helpdesk/helpdesk-variant-1.component';
import {
    ANALYTICS_API_URL,
    ANALYTICS_LOG_TO_CONSOLE,
    ANALYTICS_SEND_TO_TAGMANAGER,
    API_URL,
    BRAND_AS_URL_QUERY_PARAMETER,
    BUSINESS,
    CANONICAL_COMMUNITY,
    CAN_ACTIVATE_LANDING,
    CMS_ALLOW_LINKS,
    CMS_LOCALE,
    CMS_PRODUCT,
    CMS_REGION,
    CMS_URL,
    configureOAuth,
    COOKIE_POLICY_URL,
    DEFAULT_COMMUNITY,
    getAuthConfigFromEnv,
    MPARTICLE_DEVELOPMENT_MODE,
    MPARTICLE_KEY_PROVIDER_URL,
    APPLICATION_INSIGHTS_CONNECTION_STRING,
} from '../../_common/app/app.config';
import { ErrorComponent } from '../../_common/components/error/error.component';
import { PendingChangesGuard } from '../../_common/infrastructure/pending-changes.guard';
import { AuthService } from '../../_common/services/auth.service';
import { ContactService } from '../../_common/services/contact.service';
import { ContentService } from '../../_common/services/content.service';
import { GoogleAnalyticsService } from '../../_common/services/google-analytics.service';
import { InMemoryQueryService } from '../../_common/services/inmemory.query.service';
import { KenalyticsService } from '../../_common/services/kenalytics.service';
import { MonitoringService } from '../../_common/services/monitoring.service';
import { SeoService } from '../../_common/services/seo.service';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppConfig } from '@enuk/model/app-config';
import { APP_CONFIG, AppConfigService } from '@enuk/services/app-config.service';
import { ContactComponent } from './components/contact/contact.component';
import { FaqComponent } from './components/faq/faq.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { InfoAboutUsComponent } from './components/info/about-us/about-us.component';
import { InfoComponent } from './components/info/info.component';
import { InfoTestimonialComponent } from './components/info/testimonial/testimonial.component';
import { LandingComponent } from './components/landing/landing.component';
import { PastSuppliersComponent } from './components/past-suppliers/past-suppliers.component';
import { RegisterEmailConfirmationComponent } from './components/register/email-confirmation/email-confirmation.component';
import { EnergyComponent } from './components/register/energy/energy.component';
import { EnergySupplierComponent } from './components/register/energy/supplier/supplier.component';
import { EnergyPaymentMethodComponent } from './components/register/energy/payment-method/payment-method.component';
import { EnergyTariffSelectComponent } from './components/register/energy/tariff-select/tariff-select.component';
import { RegisterOfferIntroductionComponent } from './components/register/offer-introduction/offer-introduction.component';
import { CollapsibleCardComponent } from './components/register/overview/collapsible-card/collapsible-card.component';
import { OverviewEnergyDetailsComponent } from './components/register/overview/energy-details/energy-details.component';
import { OverviewComponent } from './components/register/overview/overview.component';
import { OverviewPersonalDetailsComponent } from './components/register/overview/personal-details/personal-details.component';
import { PersonCanBeContactedComponent } from './components/register/person/can-be-contacted/can-be-contacted.component';
import { PersonComponent } from './components/register/person/person.component';
import { PublicityChannelComponent } from './components/register/person/publicity-channel/publicity-channel.component';
import { CeasedTradingComponent } from './components/register/proposal/ceased-trading/ceased-trading.component';
import { DecisionPreviousAddressComponent } from './components/register/proposal/previous-address-component/previous-address.component';
import { ProposalInformationComponent } from './components/register/proposal/proposal-information/proposal-information.component';
import { ProposalComponent } from './components/register/proposal/proposal.component';
// eslint-disable-next-line max-len
import { TariffInformationLabelComponent } from './components/register/proposal/tariff-information-label/tariff-information-label.component';
import { TariffLookupModalComponent } from './components/register/proposal/tariff.lookup.modal.component';
import { RegisterComponent } from './components/register/register.component';
import { SocialComponent } from './components/register/social/social.component';
import { StepperHeaderComponent } from './components/register/stepper/stepper-header.component';
import { ComparisonTableComponent } from './components/register/tarrif-comparison/comparison.table.component';
import { TarrifComparisonComponent } from './components/register/tarrif-comparison/tarrif-comparison.component';
import { EnUkTooltipComponent } from './components/tooltip/tooltip.component';
import { CouncilRouteGuard } from './guards/council-route-guard';
import { NgbUkDateParserFormatter } from './formatters/ngb-uk-date-parser-formatter';
import { CommunityOrSubBrandRedirectGuard } from './guards/community-or-sub-brand-redirect-guard';
import { MenuMode } from './model/menu-configuration';
import { FormatAddressPipe } from './pipes/format-address.pipe';
import { FormatNumberDefaultPipe } from './pipes/format-number-default.pipe';
import { AddressesService } from './services/addresses.service';
import { EnUkBrandService } from './services/brand.service';
import { ComparisonAbTestService } from './services/comparison-ab-test.service';
import { ComparisonService } from './services/comparison.service';
import { EnUkCommunityService } from './services/enuk-community.service';
import { EnukSeoService } from './services/enuk-seo.service';
import { ErrorHandlerService } from './services/error-handler.service';
import { FeatureFlagsService } from './services/feature-flags.service';
import { InfoItemService } from './services/info-item.service';
import { MpasService } from './services/mpas.service';
import { RegistrationService } from './services/registration.service';
import { StepperHelperService } from './services/stepper-helper.service';
import { SupplierService } from './services/supplier.service';
import { TariffService } from './services/tariff.service';
import { PostcodeValidator } from './validators/postcode.validator';
import { RegistrationUserCardComponent } from './components/register/user/registration-user-card.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { ParticipantStatusGuard } from './guards/participant-status.guard';
import { UserChangedComponent } from './components/user-changed/user-changed.component';
import { EnukCommandComponent } from './components/enuk-command/enuk-command.component';
import { AuthGuard } from './guards/auth.guard';
import { UkLangModule } from '@lang/uk/uk.lang.module';
import { AnalyticsEnricherProvider } from '@common/analytics/services/analytics-enricher.service';
import { MParticleService } from '@common/mparticle/services/m-particle.service';
import { EnUkMParticleService } from './services/en-uk-m-particle.service';
import { CookiePolicyComponent } from './components/cookie-policy/cookie-policy.component';
import { VwoStoreService } from '@common/services/vwo-store.service';
import { EnUkParticipationAssetsService } from './services/enuk-participation-assets.service';
import { AnalyticsResolver, AnalyticsResolverProvider } from '@common/analytics/resolvers/analytics.resolver';
import { AnalyticsSolver } from './solvers/analytics.solver';
import { ProcessStepService } from './services/process-step.service';
import { ProcessGoalEventFactory } from '@common/analytics/services/process-goal-event-factory.service';
import { EnukProcessGoalEventFactory } from './services/process-goal-event-factory.service';
import { EnUkMonitoringService } from '@enuk/services/monitoring.service';
import { ParticipationAssetsService } from '@common/services/participation-assets.service';

registerLocaleData(localeGB, 'en-GB');

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        RouterModule.forRoot(
            [
                {
                    path: '',
                    pathMatch: 'full',
                    redirectTo: document.location.hostname !== 'app.ichoosr.com' ? 'landing' : 'ichoosr',
                },
                {
                    path: 'landing',
                    component: LandingComponent,
                    canActivate: [CouncilRouteGuard],
                },
                {
                    path: 'terms-and-conditions',
                    component: TermsAndConditionsComponent,
                },
                {
                    path: 'privacy-statement',
                    component: PrivacyPolicyComponent,
                },
                {
                    path: 'cookie-policy',
                    component: CookiePolicyComponent,
                },
                { path: 'info/:id', component: InfoComponent },
                { path: 'info', redirectTo: 'info/how-does-it-work' },
                { path: 'faq/:id', component: FaqComponent },
                { path: 'faq', component: FaqComponent },
                { path: 'contact', component: ContactComponent, data: { menuMode: MenuMode.Hide } },
                {
                    path: 'user-changed',
                    component: UserChangedComponent,
                    data: { titleKey: 'user-changed' },
                },
                {
                    path: ':community',
                    children: [
                        {
                            path: '',
                            pathMatch: 'full',
                            canActivate: [CommunityOrSubBrandRedirectGuard],
                            children: [],
                        },
                        {
                            path: 'landing',
                            component: LandingComponent,
                            canActivate: [CommunityOrSubBrandRedirectGuard],
                        },
                        { path: 'home', component: HomeComponent },
                        { path: 'info/:id', component: InfoComponent },
                        { path: 'info', redirectTo: 'info/how-does-it-work' },
                        { path: 'faq/:id', component: FaqComponent },
                        { path: 'faq', component: FaqComponent },
                        { path: 'contact/:id', component: ContactComponent },
                        { path: 'contact', component: ContactComponent },
                        {
                            path: 'register/:id',
                            component: RegisterComponent,
                            canActivate: [ParticipantStatusGuard, AuthGuard],
                            canDeactivate: [PendingChangesGuard],
                            data: { menuMode: MenuMode.Step },
                            resolve: { analyticsState: AnalyticsResolver },
                        },
                        {
                            path: 'register',
                            component: RegisterComponent,
                            canDeactivate: [PendingChangesGuard],
                            data: { menuMode: MenuMode.Step },
                            resolve: { analyticsState: AnalyticsResolver },
                        },
                        { path: 'error', component: ErrorComponent },
                        { path: 'error/:id', component: ErrorComponent },
                        {
                            path: 'do/:id/:command',
                            component: EnukCommandComponent,
                            canActivate: [ParticipantStatusGuard, AuthGuard],
                        },
                        {
                            path: 'unauthorized/:id',
                            component: UnauthorizedComponent,
                            data: { titleKey: 'unauthorized' },
                        },
                        { path: '**', redirectTo: 'home' },
                    ],
                },
            ],
            {},
        ),
        NgbModule,
        FileUploadModule,
        BrowserModule,
        AppCommonModule,
        NgxMaskModule.forRoot(),
        OAuthModule.forRoot(),
        NgSelectModule,
        UkLangModule,
    ],
    declarations: [
        MarkOptionalDirective,
        PostcodeValidator,
        FormatAddressPipe,
        FormatNumberDefaultPipe,
        AppComponent,
        EnUkTooltipComponent,
        ListMenuComponent,
        FooterComponent,
        HomeComponent,
        InfoComponent,
        InfoAboutUsComponent,
        InfoTestimonialComponent,
        FaqComponent,
        RegisterNowButtonComponent,
        RegisterNowBannerComponent,
        RegisterComponent,
        ContactComponent,
        PersonComponent,
        PersonCanBeContactedComponent,
        PublicityChannelComponent,
        ProposalComponent,
        TarrifComparisonComponent,
        ComparisonTableComponent,
        DecisionPreviousAddressComponent,
        OverviewComponent,
        SurveyBannerComponent,
        CollapsibleCardComponent,
        OverviewPersonalDetailsComponent,
        OverviewEnergyDetailsComponent,
        SocialComponent,
        EnergyComponent,
        EnergySupplierComponent,
        EnergyPaymentMethodComponent,
        EnergyTariffSelectComponent,
        TariffLookupModalComponent,
        CmsItemsCheckListComponent,
        RegisterEmailConfirmationComponent,
        RegisterOfferIntroductionComponent,
        ProposalInformationComponent,
        CeasedTradingComponent,
        LandingComponent,
        TariffInformationLabelComponent,
        StepperHeaderComponent,
        CountdownComponent,
        PastSuppliersComponent,
        HelpdeskComponent,
        RegistrationUserCardComponent,
        UnauthorizedComponent,
        UserChangedComponent,
        EnukCommandComponent,
        CookiePolicyComponent,
        HelpdeskVariant1Component,
    ],
    providers: [
        {
            provide: AppConfigService,
            useFactory: (config: AppConfig) => new AppConfigService(config),
            deps: [APP_CONFIG],
        },
        {
            provide: OAuthModuleConfig,
            useFactory: (): OAuthModuleConfig => {
                return {
                    resourceServer: {
                        allowedUrls: environment.apiUri && [environment.apiUri],
                        sendAccessToken: true,
                    },
                };
            },
            deps: [AppConfigService],
        },
        {
            provide: AuthConfig,
            useFactory: () => getAuthConfigFromEnv(environment, window?.location?.hostname),
            deps: [AppConfigService],
        },
        RegistrationService,
        ContentService,
        ContactService,
        GoogleAnalyticsService,
        PendingChangesGuard,
        AddressesService,
        { provide: SeoService, useClass: EnukSeoService },
        {
            provide: MParticleService,
            useClass: EnUkMParticleService,
        },
        AuthService,
        FeatureFlagsService,
        MpasService,
        SupplierService,
        TariffService,
        KenalyticsService,
        VwoStoreService,
        ChatService,
        ComparisonService,
        InMemoryQueryService,
        InfoItemService,
        {
            provide: InfoItemServiceBase,
            useClass: InfoItemService,
        },
        MenuItemService,
        CouncilRouteGuard,
        CommunityOrSubBrandRedirectGuard,
        {
            provide: BrandService,
            useClass: EnUkBrandService,
        },
        ComparisonAbTestService,
        EnUkCommunityService,
        StepperHelperService,
        { provide: ErrorHandler, useClass: ErrorHandlerService },
        {
            provide: APPLICATION_INSIGHTS_CONNECTION_STRING,
            useFactory: () => environment.aiConnectionString,
            deps: [AppConfigService],
        },
        {
            provide: MonitoringService,
            useClass: EnUkMonitoringService,
            deps: [BUSINESS, APPLICATION_INSIGHTS_CONNECTION_STRING],
        },
        {
            provide: MPARTICLE_KEY_PROVIDER_URL,
            useFactory: () => {
                if (environment.production) {
                    return 'https://ichoosr-mparticle.ichoosr.com/api/SdkApiKeyOnlyProvider';
                } else {
                    return 'https://ichoosr-mparticle-test.ichoosr.com/api/SdkApiKeyOnlyProvider';
                }
            },
            deps: [AppConfigService],
        },
        { provide: CommunityService, useClass: EnUkCommunityService },
        {
            provide: ParticipationAssetsService,
            deps: [CommunityService, BrandService],
            useFactory: (communityService: CommunityService, brandService: BrandService) =>
                new EnUkParticipationAssetsService(communityService, brandService),
        },
        { provide: LOCALE_ID, useValue: 'en-GB' },
        {
            provide: API_URL,
            useFactory: () => environment.apiUri,
            deps: [AppConfigService],
        },
        {
            provide: ANALYTICS_API_URL,
            useFactory: () => environment.analyticsUri,
            deps: [AppConfigService],
        },
        { provide: CMS_URL, useValue: 'https://content-eu.ichoosr.com/api/' },
        {
            provide: CMS_ALLOW_LINKS,
            useFactory: () => environment.allowLinksToCms,
            deps: [AppConfigService],
        },
        { provide: CMS_PRODUCT, useValue: 'energy' },
        { provide: CMS_REGION, useValue: 'uk' },
        { provide: CMS_LOCALE, useValue: 'en' },
        { provide: BUSINESS, useValue: 'enuk' },
        { provide: CANONICAL_COMMUNITY, useValue: 'ichoosr' },
        { provide: OAuthStorage, useClass: InMemoryAuthStorage },
        { provide: ValidationHandler, useClass: JwksValidationHandler },
        {
            provide: NgbDateParserFormatter,
            useFactory: () => NgbUkDateParserFormatter.createInstance('dd/MM/yyyy'),
        },
        {
            provide: COOKIE_POLICY_URL,
            useValue: 'https://consent.cookiebot.com/971122bd-2173-45ca-9a5a-5dfce30b9bfa/cd.js',
        },
        { provide: DEFAULT_COMMUNITY, useValue: '' },
        {
            provide: CAN_ACTIVATE_LANDING,
            useFactory: () => environment.canAccessLandingPage,
            deps: [AppConfigService],
        },
        {
            provide: BRAND_AS_URL_QUERY_PARAMETER,
            useFactory: () => environment.brandAsUrlQueryParameter,
            deps: [AppConfigService],
        },
        WINDOW_PROVIDERS,
        {
            provide: AnalyticsEnricherProvider,
            deps: [RegistrationService, EnUkCommunityService],
        },
        {
            provide: ANALYTICS_SEND_TO_TAGMANAGER,
            useValue: true,
        },
        {
            provide: ANALYTICS_LOG_TO_CONSOLE,
            useFactory: () => !environment.production,
            deps: [AppConfigService],
        },
        {
            provide: MPARTICLE_DEVELOPMENT_MODE,
            useFactory: () => !environment.production,
            deps: [AppConfigService],
        },
        AnalyticsSolver,
        {
            provide: AnalyticsResolverProvider,
            deps: [RegistrationService, CommunityService, AnalyticsSolver],
        },
        ProcessStepService,
        {
            provide: ProcessGoalEventFactory,
            useClass: EnukProcessGoalEventFactory,
        },
    ],
    bootstrap: [AppComponent],
    exports: [HelpdeskComponent],
})
export class AppModule {}
