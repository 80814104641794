/* eslint-disable @typescript-eslint/naming-convention */
import {
    AnalyticsEvent,
    AnalyticsEventTemplate,
    EventName,
} from '@common/analytics/events/analytics.event';

type Template = AnalyticsEventTemplate<EnergyContractEvent>;

export class EnergyContractEvent implements AnalyticsEvent {
    event: EventName = 'energy_contract';
    energy_contract_type: string;
    current_supplier_electricity_code: string;
    current_supplier_gas_code: string;
    current_supplier_electricity_name: string;
    current_supplier_gas_name: string;
    electricity_contract_end_date: Date;
    gas_contract_end_date: Date;
    community: string;
    project_code: string;
    project_status: string;
    registration_number: string;

    constructor(template: Template = {}) {
        Object.assign(this, template);
    }
}
