import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AnalyticsEvent } from '@common/analytics/events/analytics.event';
import { AnalyticsService } from '@common/analytics/services/analytics.service';
import {
    BUSINESS,
    MPARTICLE_DEVELOPMENT_MODE,
    MPARTICLE_KEY_PROVIDER_URL,
} from '@common/app/app.config';
import { RegistrationBase } from '@common/model/registration.base';
import { AuthService } from '@common/services/auth.service';
import { CommunityService } from '@common/services/community.service';
import { RegistrationServiceBase } from '@common/services/registration.service.base';
import mParticle from '@mparticle/web-sdk';
import adwordsKit from '@mparticle/web-adwords-kit'
import facebookKit from '@mparticle/web-facebook-kit'
import onetrustKit from '@mparticle/web-onetrust-kit'
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, take } from 'rxjs/operators';
import { SdkApiKeyOnlyProvider } from '../model/sdk-api-key-only-provider';
export interface IMParticleService {
    push(event: AnalyticsEvent): void;
}

export class MParticleServiceProvider {
    constructor(
        public registrationService: RegistrationServiceBase<RegistrationBase>,
    ) {}
}

@Injectable({
    providedIn: 'root',
})
export abstract class MParticleService {
    protected ready = false;
    protected queue: AnalyticsEvent[] = [];
    registration: any;
    constructor(
        @Inject(MPARTICLE_KEY_PROVIDER_URL) protected keyProviderUrl: string,
        @Inject(BUSINESS) protected business: string,
        @Inject(MPARTICLE_DEVELOPMENT_MODE) protected developmentMode: boolean,
        protected http: HttpClient,
        protected communityService: CommunityService,
        private authService: AuthService,
        private analyticsService: AnalyticsService,
    ) {}

    public push(event: AnalyticsEvent): void {
        while (this.queue?.length && !this.ready) {
            this.determineSendMethod(this.queue.shift());
        }

        if (!this.ready) {
            this.queue?.push(event);
            return;
        }
        this.determineSendMethod(event);
    }

    private determineSendMethod(event: AnalyticsEvent): void {
        switch (event?.event) {
            case 'page_view':
                this.sendPageView(event);
                break;
            case 'contactform_submit':
                this.sendEvent(event, mParticle.EventType.UserContent);
                break;
            case 'external_link_click':
            case 'internal_link_click':
                this.sendEvent(event, mParticle.EventType.Navigation);
                break;
            default:
                this.sendEvent(event, mParticle.EventType.Other);
                break;
        }
    }

    private sendPageView(event: AnalyticsEvent): void {
        mParticle.logPageView(
            'page_view',
            Object.entries(event).reduce(
                (cur, [k, v]) => ({ ...cur, [k]: v }),
                {},
            ),
        );
    }

    private sendEvent(
        event: AnalyticsEvent,
        eventType: mParticle.EventType,
    ): void {
        mParticle.logEvent(
            event.event,
            eventType,
            Object.entries(event).reduce(
                (cur, [k, v]) => ({ ...cur, [k]: v }),
                {},
            ),
        );
    }

    public logout(): void {
        mParticle.Identity.logout({} as mParticle.IdentityApiData);
    }

    public initializeMParticle(): void {
        this.getKeyOnlyProviderCall()
            .pipe(distinctUntilChanged(), take(1))
            .subscribe((result) => {
                const config = {
                    isDevelopmentMode: this.developmentMode,
                    identityCallback: (_) => {},
                    dataPlan: {
                        planId: result.planId,
                        planVersion: result.planVersion,
                    },
                };
                adwordsKit.register(config);
                facebookKit.register(config);
                onetrustKit.register(config);
                mParticle.init(result.key, config);
                mParticle.ready(() => {
                    if (
                        this.registration &&
                        mParticle.Identity.getCurrentUser()?.isLoggedIn()
                    ) {
                        mParticle.Identity.getCurrentUser().setUserAttributes(
                            this.extractUserAttributes(this.registration),
                        );
                    }
                    this.ready = true;
                });
            });

        this.analyticsService.event$
            .pipe(filter((event) => !!event?.event))
            .subscribe((e) => this.push(e));
    }

    protected getKeyOnlyProviderCall(): Observable<SdkApiKeyOnlyProvider> {
        return this.communityService.community$.pipe(
            switchMap((com) =>
                this.http.post<SdkApiKeyOnlyProvider>(this.keyProviderUrl, {
                    businessLine: this.business,
                    communityOrBrandCode:
                        (com as any).parentBrand?.code ?? com.code,
                }),
            ),
            take(1),
        );
    }

    // use registrationservice get (make sure to check if user is already logged in) and add (always log in) methods to trigger login
    // use authservice to validate role (participant)
    // check what happens when email address changes, should login be called again (check current user email address).
    // NEVER call modify, handled by the mparticle api
    public login(registration: any): void {
        this.registration = registration;
        const userIdentities = this.extractIdentities(this.registration);
        if (this.ready && userIdentities) {
            mParticle?.Identity?.login(
                {
                    userIdentities,
                },
                () => {
                    mParticle.Identity.getCurrentUser().setUserAttributes(
                        this.extractUserAttributes(this.registration),
                    );
                },
            );
        }
    }

    protected extractIdentities(_registration: any): mParticle.UserIdentities {
        return null;
    }

    protected extractUserAttributes(
        _registration: any,
    ): Record<string, unknown> {
        return null;
    }
}
