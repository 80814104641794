/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable prettier/prettier */
//TODO remove this once prettier has been updated

import { ContactFormSubmitEvent } from './contactform-submit.event';
import { LinkClickEvent } from './link-click.event';
import { PageViewEvent } from './pageview.event';
import { ProcessGoalEvent } from './process-goal.event';
import { ProcessEvent } from './process.event';
import { ProposalInteractionEvent } from './proposal-interaction.event';
import { RoofNotSuitableEvent } from './roof-not-suitable.event';

export const processGoalEventNames = [
    'minimal_registration',
    'interested_registration',
    'created_registration',
    'completed_registration',
    'email_confirmed',
    'proposal_accepted',
    'proposal_declined',
    'payment_successful',
] as const;
export type ProcessGoalEventName = typeof processGoalEventNames[number];

export const processEventNames = ['process_interaction', 'process_start', 'process_finished'] as const;
export type ProcessEventName = typeof processEventNames[number];

export const linkClickEventNames = [
    'internal_link_click',
    'external_link_click',
    'crossbusiness_link_click',
] as const;
export type LinkClickEventName = typeof linkClickEventNames[number];

export const eventNames = [
    'page_view',
    'faq_impression',
    'contactform_submit',
    'video_interaction',
    'download',
    'tooltip_click',
    'error',
    'proposal_interaction',
    'roof_not_suitable',
    'attribution',
    'energy_contract',
    'existing_registration',
    'cookies_accepted',
    ...linkClickEventNames,
    ...processGoalEventNames,
    ...processEventNames,
] as const;
export type EventName = typeof eventNames[number];

export interface AnalyticsEvent {
    event: EventName;
}

/**
 * A type that contains fields for a specific event that is not part of the {@link AnalyticsEvent} interface.
 * All the fields have been made optional.
 */
export type AnalyticsEventTemplate<T extends AnalyticsEvent> = Partial<Omit<T, keyof AnalyticsEvent>>;

// Matchers

export const isProcessGoalEvent = (event: AnalyticsEvent): event is ProcessGoalEvent<any> =>
    event instanceof ProcessGoalEvent || (event?.event && processGoalEventNames.includes(event.event as any));

export const isProcessEvent = (event: AnalyticsEvent): event is ProcessEvent =>
    event instanceof ProcessEvent || (event?.event && processEventNames.includes(event.event as any));

export const islinkClickEvent = (event: AnalyticsEvent): event is LinkClickEvent =>
    event instanceof LinkClickEvent || (event?.event && linkClickEventNames.includes(event.event as any));

export const isPageViewEvent = (event: AnalyticsEvent): event is PageViewEvent =>
    event instanceof PageViewEvent || (event?.event && event.event === 'page_view');

export const isProposalInteractionEvent = (event: AnalyticsEvent): event is ProposalInteractionEvent =>
    event instanceof ProposalInteractionEvent || (event?.event && event.event === 'proposal_interaction');

export const isContactFormSubmitEvent = (event: AnalyticsEvent): event is ContactFormSubmitEvent =>
    event instanceof ContactFormSubmitEvent || (event?.event && event.event === 'contactform_submit');

export const isRoofNotSuitableEvent = (event: AnalyticsEvent): event is RoofNotSuitableEvent =>
    event instanceof RoofNotSuitableEvent || (event?.event && event.event === 'roof_not_suitable');
