/* eslint-disable @typescript-eslint/naming-convention */
import {
    AnalyticsEvent,
    AnalyticsEventTemplate,
} from '@common/analytics/events/analytics.event';
import { RegistrationBase } from '@common/model/registration.base';
import { UtmEventData } from '@common/services/kenalytics.service';
import { getFlowPhase } from '@common/analytics/model/flow-phase';
type Template = AnalyticsEventTemplate<AttributionEvent>;

export class AttributionEvent implements AnalyticsEvent {
    event = 'attribution' as const;

    utm_source: string;
    utm_medium: string;
    utm_campaign: string;
    utm_content: string;
    utm_term: string;
    community: string;
    project_code: string;
    project_status: string;
    registration_number: string;

    constructor(template: Template = {}) {
        Object.assign(this, template);
    }

    static forUtmEventDataAndRegistration(
        utms: UtmEventData,
        registration: RegistrationBase,
    ): AttributionEvent {
        return new AttributionEvent({
            utm_source: utms?.utm_source,
            utm_medium: utms?.utm_medium,
            utm_campaign: utms?.utm_campaign,
            utm_content: utms?.utm_content,
            utm_term: utms?.utm_term,
            community: registration?.community?.name,
            project_code: registration?.auction?.code,
            project_status: getFlowPhase(registration, registration?.community),
            registration_number: registration?.number,
        });
    }
}
