/* eslint-disable @typescript-eslint/naming-convention */
import {
    AnalyticsEvent,
    AnalyticsEventTemplate,
    ProcessGoalEventName,
} from '@common/analytics/events/analytics.event';
import { FlowPhase } from '@common/analytics/model/flow-phase';
import { FlowStart } from '@common/analytics/model/flow-start';
import { ProposalFlowSource } from '@common/analytics/model/proposal-flow-source';

/** Event parameters that can be calculated from common, and therefore should never be provided from the BL code */
export type CommonProcessGoalEventParams = Pick<
    ProcessGoalEvent<any>,
    'process_flow_start' | 'process_flow_phase' | 'proposal_flow_source'
>;

type Template = Omit<AnalyticsEventTemplate<ProcessGoalEvent<any>>, keyof CommonProcessGoalEventParams>;

export abstract class ProcessGoalEvent<TName extends ProcessGoalEventName> implements AnalyticsEvent {
    event: TName;
    process_flow_phase: FlowPhase;
    process_flow_start: FlowStart;
    interested_addon: string;
    proposal_flow_source: ProposalFlowSource;
    rollover: boolean;

    constructor(event: TName, template: Template = {}) {
        this.event = event;
        Object.assign(this, template);
    }
}

export class MinimalRegistrationEvent extends ProcessGoalEvent<'minimal_registration'> {
    constructor(template: Template = {}) {
        super('minimal_registration', template);
    }

    public static create(params: Template = {}) {
        return new this({ ...params });
    }
}
export class CreatedRegistrationEvent extends ProcessGoalEvent<'created_registration'> {
    constructor(template: Template = {}) {
        super('created_registration', template);
    }

    public static create(params: Template = {}) {
        return new this({ ...params });
    }
}

export class CompletedRegistrationEvent extends ProcessGoalEvent<'completed_registration'> {
    constructor(template: Template = {}) {
        super('completed_registration', template);
    }

    public static create(params: Template = {}) {
        return new this({ ...params });
    }
}

export class ProposalAcceptedEvent extends ProcessGoalEvent<'proposal_accepted'> {
    constructor(template: Template = {}) {
        super('proposal_accepted', template);
    }

    public static create(params: Template = {}) {
        return new this({ ...params });
    }
}

export class ProposalDeclinedEvent extends ProcessGoalEvent<'proposal_declined'> {
    constructor(template: Template = {}) {
        super('proposal_declined', template);
    }

    public static create(params: Template = {}) {
        return new this({ ...params });
    }
}

export class InterestedRegistrationEvent extends ProcessGoalEvent<'interested_registration'> {
    constructor(template: Template = {}) {
        super('interested_registration', template);
    }

    public static create(params: Template = {}) {
        return new this({ ...params });
    }
}

export class PaymentSuccessfullEvent extends ProcessGoalEvent<'payment_successful'> {
    constructor(template: Template = {}) {
        super('payment_successful', template);
    }

    public static create(params: Template = {}) {
        return new this({ ...params });
    }
}

export class EmailConfirmedEvent extends ProcessGoalEvent<'email_confirmed'> {
    constructor(template: Template = {}) {
        super('email_confirmed', template);
    }

    public static create(params: Template = {}) {
        return new this({ ...params });
    }
}
