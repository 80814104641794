import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AnalyticsEvent } from '@common/analytics/events/analytics.event';
import { BUSINESS, MPARTICLE_DEVELOPMENT_MODE, MPARTICLE_KEY_PROVIDER_URL } from '@common/app/app.config';
import { CommunityService } from '@common/services/community.service';
import { MParticleService } from '@common/mparticle/services/m-particle.service';
import { BrandService } from '@lang/uk/services/brand.service';
import { combineLatest, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SdkApiKeyOnlyProvider } from '@common/mparticle/model/sdk-api-key-only-provider';
import { Community } from '@enuk/model/community';
import { AuthService } from '@common/services/auth.service';
import { RegistrationService } from './registration.service';
import { AnalyticsService } from '@common/analytics/services/analytics.service';
import { isNullOrUndefined, removeNullishAndEmpty } from '@common/util';
export interface IMParticleService {
    push(event: AnalyticsEvent): void;
}

@Injectable({
    providedIn: 'root',
})
export class EnUkMParticleService extends MParticleService {
    constructor(
        @Inject(MPARTICLE_KEY_PROVIDER_URL) keyProviderUrl: string,
        @Inject(BUSINESS) business: string,
        @Inject(MPARTICLE_DEVELOPMENT_MODE) developmentMode: boolean,
        http: HttpClient,
        communityService: CommunityService,
        private brandService: BrandService,
        authService: AuthService,
        googleAnalyticsService: AnalyticsService,
    ) {
        super(
            keyProviderUrl,
            business,
            developmentMode,
            http,
            communityService,
            authService,
            googleAnalyticsService,
        );
    }

    override getKeyOnlyProviderCall(): Observable<SdkApiKeyOnlyProvider> {
        return this.brandService.getActive().pipe(
            switchMap((brand) =>
                this.http.post<SdkApiKeyOnlyProvider>(this.keyProviderUrl, {
                    businessLine: this.business,
                    communityOrBrandCode: brand.parentBrand?.code,
                }),
            ),
        );
    }

    override extractIdentities(registration: any): mParticle.UserIdentities {
        if (isNullOrUndefined(registration.email)) {
            return null;
        }
        const userIdentities = {
            email: registration?.email,
            other: registration?.profileId,
            mobile_number: registration?.contact?.mobilePhone,
            phone_number_2: registration?.contact?.phone,
        } as mParticle.UserIdentities;
        return removeNullishAndEmpty(userIdentities);
    }

    override extractUserAttributes(registration): Record<string, unknown> {
        if (isNullOrUndefined(registration.email)) {
            return null;
        }
        const attributes = ['firstName', 'lastName', 'postcode', 'mobilePhone'];
        return Object.entries(registration?.contact)
            .filter(([key, _]) => attributes.includes(key))
            .reduce((cur, [k, v]) => ({ ...cur, [this.translateAttributeKeys(k)]: v }), {});
    }

    private translateAttributeKeys(key: string): string {
        switch (key) {
            case 'firstName':
                return '$firstName';
            case 'lastName':
                return '$lastName';
            case 'postcode':
                return '$zip';
            case 'mobilePhone':
                return '$mobile';
            default:
                return key;
        }
    }
}
